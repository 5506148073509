import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getUserInfo, useAuth } from "../firebase"
import { Helmet } from "react-helmet";

import { default as Loading } from "./loadingPage"

import "../style/dashboard.css"
import { appName, network } from '../App'

export default function Dashboard() {
    const currentUser = useAuth(null);
    const [user, setUser] = useState();

    const [loading, setLoading] = useState(true);
    const [heroBackground, setHeroBackground] = useState("https://firebasestorage.googleapis.com/v0/b/acron-fea63.appspot.com/o/images%2FPvwLfDG0v4ZeCJrjcT1NsokbDp83-166491428494720220708_075409.jpg?alt=media&token=8161b37c-8ce5-4bd4-84c4-5a7beb36167a");

    useEffect(() => {
        setLoading(false)
        console.log(currentUser)
    }, [currentUser])

    useEffect(() => {
        if (!currentUser) return
        getUserInfo(currentUser?.uid).then(res => {
            // console.log(res)
            setUser(res)
            if (res.dashboardBackground) { setHeroBackground(res.dashboardBackground) }
        })
    }, [currentUser])

    return <>
        {currentUser && <>
            <Helmet>
                <title>Dashboard | {appName} | {network}</title>
                <meta name="description" content="Dashboard for an {appName} account. | {appName} is a development platform testing React and Firebase login. | {network}" />
            </Helmet>
            <section className="dashboard">
                <div className="hero">
                    <div className="weather"></div>
                    <div className="welcome">
                        <h1>Welcome Back, {user?.firstname}</h1>
                        <span>{user?.firstname} {user?.lastname} | {currentUser?.email}</span>
                    </div>
                    <img src={heroBackground} alt="" className="heroBackground" />
                </div>
                <div className="container">

                </div>
            </section>
        </>}
        {currentUser === null && !loading && <Navigate to='/login' />}
        {loading && <Loading />}
    </>
}