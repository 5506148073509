// imports
import { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, updateProfile } from "firebase/auth";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, onSnapshot, query, setDoc, updateDoc } from "firebase/firestore";
// import { v4 as uuid } from "uuid"


// config
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_AUTH_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_AUTH_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_AUTH_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_AUTH_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_AUTH_APP_ID
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const auth = getAuth();
export var error = null;
const storage = getStorage();
const db = getFirestore(app);

// functions
export function signup(email, password) {
  return createUserWithEmailAndPassword(auth, email, password)
    .catch(err => {
      error = err;
    })
}

export function login(email, password) {
  return signInWithEmailAndPassword(auth, email, password)
    .catch(err => {
      switch (err.code) {
        case 'auth/invalid-email':
          error = 'Invalid Email';
          break;

        case 'auth/invalid-password':
          error = 'Invalid Password';
          break;

        case 'auth/too-many-requests':
          error = 'Too Many Requests - Reset Password';
          break;

        default:
          error = err.code;
          break;
      }
    })
}

export function logout() {
  return signOut(auth)
    .catch(err => {
      error = err;
    })
}

// Custom Hook
export function useAuth(falseValue) {
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, user => setCurrentUser(user));
    return unsub;
  }, [])

  if (currentUser === undefined && falseValue) { setCurrentUser(falseValue) }

  return currentUser;
}

// Storage
export async function uploadProfilePicture(file, currentUser, setLoading) {
  const fileEXT = file.name.split(".").pop();
  if (fileEXT !== "jpg" && fileEXT !== "jpeg" && fileEXT !== "png" && fileEXT !== "apng" && "fileEXT" !== "webp" && fileEXT !== "webm" && fileEXT !== "gif") {
    console.error("Unsupported Format");
    alert("Unsupported Format")
    return
  }
  const fileRef = ref(storage, "images/profile/" + currentUser.uid + '.' + fileEXT);

  setLoading(true);

  await uploadBytes(fileRef, file);
  const photoURL = await getDownloadURL(fileRef);

  updateProfile(currentUser, { photoURL });
  try {
    const docTest = await getDoc(doc(db, "users", currentUser.uid))
    if (docTest.data() !== undefined) {
      await updateDoc(doc(db, "users", currentUser.uid), {
        photoURL: photoURL
      })
    } else {
      await setDoc(doc(db, "users", currentUser.uid), {
        photoURL: photoURL
      })
    }
  } catch (e) {
    console.error("Error adding document (pPU): ", e);
  }

  setLoading(false);
  alert("Avatar changed, refresh page to view changes.");
}

export async function uploadProfileBackgroundPicture(file, currentUser, setLoading) {
  const fileEXT = file.name.split(".").pop();
  if (fileEXT !== "jpg" && fileEXT !== "jpeg" && fileEXT !== "png" && fileEXT !== "apng" && "fileEXT" !== "webp" && fileEXT !== "webm" && fileEXT !== "gif") {
    console.error("Unsupported Format");
    alert("Unsupported Format")
    return
  }
  const fileRef = ref(storage, "images/profile/" + currentUser.uid + '-background.' + fileEXT);

  setLoading(true);

  await uploadBytes(fileRef, file);
  const photoURL = await getDownloadURL(fileRef);

  try {
    const docTest = await getDoc(doc(db, "users", currentUser.uid))
    if (docTest.data() !== undefined) {
      await updateDoc(doc(db, "users", currentUser.uid), {
        photoBackgroundURL: photoURL
      })
    } else {
      await setDoc(doc(db, "users", currentUser.uid), {
        photoBackgroundURL: photoURL
      })
    }
  } catch (e) {
    console.error("Error adding document (pBU): ", e);
  }

  setLoading(false);
  alert("Avatar changed, refresh page to view changes.");
}

export async function updateDisplayName(displayName, currentUser, setLoading) {
  setLoading(true);

  updateProfile(currentUser, { displayName });
  try {
    const docTest = await getDoc(doc(db, "users", currentUser.uid))
    if (docTest.data() !== undefined) {
      await updateDoc(doc(db, "users", currentUser.uid), {
        displayname: displayName
      })
    } else {
      await setDoc(doc(db, "users", currentUser.uid), {
        displayname: displayName
      })
    }
  } catch (e) {
    console.error("Error adding document (dN): ", e);
  }

  setLoading(false);
  alert("Display name changed, refresh page to view changes.");
}

export async function updateFirstName(firstName, currentUser, setLoading) {
  setLoading(true);

  try {
    const docTest = await getDoc(doc(db, "users", currentUser.uid))
    if (docTest.data() !== undefined) {
      await updateDoc(doc(db, "users", currentUser.uid), {
        firstname: firstName
      })
    } else {
      await setDoc(doc(db, "users", currentUser.uid), {
        firstname: firstName
      })
    }
  } catch (e) {
    console.error("Error adding document (fN): ", e);
  }

  setLoading(false);
  alert("First name changed, refresh page to view changes.");
}

export async function updateLastName(lastName, currentUser, setLoading) {
  setLoading(true);

  try {
    const docTest = await getDoc(doc(db, "users", currentUser.uid))
    if (docTest.data() !== undefined) {
      await updateDoc(doc(db, "users", currentUser.uid), {
        lastname: lastName
      })
    } else {
      await setDoc(doc(db, "users", currentUser.uid), {
        lastname: lastName
      })
    }
  } catch (e) {
    console.error("Error adding document (lN): ", e);
  }

  setLoading(false);
  alert("First name changed, refresh page to view changes.");
}

export async function getUserInfo(userID) {
  try {
    const docSnap = await getDoc(doc(db, "users", userID));
    return docSnap.data();
  } catch (e) {
    console.error("Error getting user: ", e);
  }
}

export async function getImageInfo(imageID) {
  try {
    const docSnap = await getDoc(doc(db, "images", imageID));
    return docSnap.data();
  } catch (e) {
    console.error("Error getting image: ", e);
  }
}

export async function uploadImage(file, currentUser) {
  var type = "photo";

  const fileEXT = file.name.split(".").pop();
  if (fileEXT !== "jpg" && fileEXT !== "jpeg" && fileEXT !== "png" && fileEXT !== "apng" && "fileEXT" !== "webp" && fileEXT !== "webm" && fileEXT !== "gif" && fileEXT === "mp4") {
    console.error("Unsupported Format");
    alert("Unsupported Format")
    return
  }
  if (fileEXT === "webm" || fileEXT === "mp4") {
    type = "video";
  }
  const fileRef = ref(storage, "images/" + currentUser.uid + '-' + Date.now() + file.name);
  await uploadBytes(fileRef, file);
  const photoURL = await getDownloadURL(fileRef);

  try {
    const docSnap = await addDoc(collection(db, "images"), {
      type: type,
      url: photoURL,
      user: currentUser.uid
    });

    console.log("Document written with ID: ", docSnap.id);
    return docSnap.id
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

// posts
export async function createPost(title, body, files, currentUser, setLoading) {
  var arr = [];
  // files.forEach(file => {
  // })

  for (let i = 0; files.length > i; i++) {
    console.log(files.item(i))
    await uploadImage(files.item(i), currentUser).then(res => {
      arr.push(res);
    })
  }

  setLoading(true)
  try {
    const docSnap = await addDoc(collection(db, "posts"), {
      title: title,
      body: body,
      images: arr,
      user: currentUser.uid
    });

    // await updateDoc(collection(db, "posts", docSnap.id), {
    //   postID: docSnap.id
    // })

    console.log("Document written with ID: ", docSnap.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
  setLoading(false);
}

export async function getPost(postID) {
  const docRef = doc(db, "posts", postID);
  const docSnap = await getDoc(docRef);
  return docSnap.data();
}

export async function usePosts() {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    const unsub = onSnapshot(query(collection(db, "posts")), (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var arr = docs;
        if (arr.some(e => e.id === doc.id)) return
        var currentDoc = doc.data();
        currentDoc.id = doc.id;
        docs.push(currentDoc);
        setDocs(docs)
      })
    })
    return unsub
  })

  return docs
}

export async function createComment(postID, parentId, title, body, currentUser, setLoading) {
  setLoading(true)
  try {
    const docRef = await addDoc(collection(db, "comments"), {
      postID: postID,
      parentId: parentId,
      title: title,
      body: body,
      user: currentUser.id
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
  setLoading(false);
}

export async function getComment(postID) {

}

export async function getComments(postID) {

}