import { useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import {
    useAuth,
    createPost
} from "../firebase";

import "../style/newPost.css"

export default function NewPost() {
    const currentUser = useAuth();
    const titleRef = useRef();
    const bodyRef = useRef();
    const fileRef = useRef();
    const [loading, setLoading] = useState(false);
    const [photos, setPhotos] = useState(false);

    function handleBackgroundFileChange(e) {
        if (e.target.files[0]) {
            setPhotos(e.target.files[0])
        }
    }

    return <section className="newPost">
        <div className="container">
            <input type="text" name="title" id="" ref={titleRef} cols="30" rows="10" placeholder='Title'></input>
            <textarea name="body" id="" ref={bodyRef} cols="30" rows="10" placeholder='Body'></textarea>
            <div className="input">
                <label htmlFor="background" className="file-input">Upload Background
                    <input type="file" id="background" ref={fileRef} onChange={handleBackgroundFileChange} accept=".jpg, .jpeg, .png, .apng, .webp, .webm, .gif" multiple={true}/>
                </label>
            </div>
            <button disabled={loading} onClick={() => createPost(titleRef.current.value, bodyRef.current.value, fileRef.current.files, currentUser, setLoading)}>Create Post Test</button>
        </div>
    </section>
}