import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../firebase";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import '../style/user.css'
import { appName, network } from '../App'

export default function User() {
    const currentUser = useAuth();
    const [photoURL, setPhotoURL] = useState("https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png");

    useEffect(() => {
        if (currentUser?.photoURL) {
            setPhotoURL(currentUser.photoURL);
        }
    }, [currentUser])

    return (
        <>
            <Helmet>
                <title>You on | {appName} | {network}</title>
                {currentUser?.email && <title>{currentUser?.email} | {appName} | {network}</title>}
                {currentUser?.displayName && <title>{currentUser?.displayName} | {appName} | {network}</title>}
                <meta name="description" content="Login for an {appName} account. | {appName} is a development platform testing React and Firebase login. | {network}" />
            </Helmet>
            <section className="user">
                <div className="container">
                    <img src={photoURL} alt="Avatar" className="avatar" />
                    <div className="controls">
                        <Link to="profile">Profile</Link>
                        <Link to="account">Account</Link>
                    </div>
                </div>
            </section>
            <Outlet />
        </>
    );
}