import { forwardRef, useState } from "react"
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown'

import { getImageInfo, getUserInfo, usePosts } from "../firebase";

import "../style/feed.css"
import { Helmet } from "react-helmet";
import { appName, network } from '../App'

export default function Feed() {
    const [posts, setPosts] = useState([]);

    usePosts().then(res => {
        setPosts(res)
    })
    
    return <>
        <Helmet>
            <title>Feed | {appName} | {network}</title>
            <meta name="description" content="{doc?.title} by {doc?.displayname} | {appName} is a development platform testing React and Firebase login. | {network}" />
        </Helmet>
        <section className="feed">
            <div className="container">
                {console.log(posts)}
                {posts.map((post, index) => (
                    <PostItem
                        post={post}
                        index={index}
                    />
                ))}
            </div>
        </section>
    </>
}

const PostItem = forwardRef(
    (
        {
            post,
            index
        },
        ref
    ) => {
        const [user, setUser] = useState();
        const [images, setImages] = useState([]);

        getUserInfo(post.user).then(resp => {
            setUser(resp)
        })
        if (post.images) {
            for (let i = 0; i < post.images.length; i++) {
                getImageInfo(post.images[i]).then(resp => {
                    var arr = images;
                    // console.log(resp, arr)
                    if (arr.some(e => e.url === resp.url)) return
                    arr.push(resp)
                    setImages(arr);
                    console.log(arr)
                })

            }
        }

        return <div className="post" ref={ref} key={index} id={"post-" + post?.id}>
            <div className="header">
                <Link className="avatar" to={"/user/" + post?.user}>
                    <img src={user?.photoURL} alt="" />
                </Link>
                <div className="info">
                    <Link className="displayName" to={"/user/" + post?.user}>
                        {user?.displayname}
                    </Link>

                    <span>{post?.title}</span>
                </div>
            </div>
            <div className="content">
                <ReactMarkdown>{post?.body}</ReactMarkdown>
                {images.length > 0 && <div className="images">
                    {images.map((image) => {
                        console.log(image.type)
                        if (image.type === "photo") {
                            return <div className="image" key={image}>
                                <img src={image.url} alt="" />
                            </div>
                        } else if (image.type === "video") {
                            return <div className="image" key={image}>
                                <video src={image.url} alt="" loop={true} controls={true} autoPlay={true} muted={true}></video>
                            </div>
                        } else {
                            return <></>
                        }
                    })}
                </div>}
            </div>
            <div className="controls">
                <div className="section">
                    <button className="like">LIKE</button>
                    <span>XX</span>
                </div>
                <div className="section">
                    <button className="share">SHARE</button>
                    <span>XX</span>
                </div>
            </div>
        </div>
            ;
    }
);
