// embeds
import { useRef, useState } from "react";
import { login, useAuth, error } from "../firebase";
import { Navigate } from "react-router-dom";

import { default as Background } from '../symbols/background'
import { appName, network } from '../App'

// css
import '../style/login.css'

// firebase
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet";

function Login() {
    const [loading, setLoading] = useState(false);
    const currentUser = useAuth();

    const emailRef = useRef();
    const passwordRef = useRef();

    async function handleLogin(e) {
        e.preventDefault();
        setLoading(true);
        try {
            await login(emailRef.current.value, passwordRef.current.value);
        } catch (error) {
        }
        setLoading(false);
    }

    return (
        <>
            <Helmet>
                <title>Login | {appName} | {network}</title>
                <meta name="description" content="Login for an {appName} account. | {appName} is a development platform testing React and Firebase login. | {network}" />
            </Helmet>
            <section className="login">
                <div className="container">
                    {currentUser && <Navigate to='/me' />}
                    <form action="">
                        <h2>Login</h2>
                        {error && <div className="error">Error: {error}</div>}
                        <div className="group">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" ref={emailRef} placeholder='example@acron.xcwalker.dev' pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?" required />
                        </div>
                        <div className="group">
                            <label htmlFor="password">Password <Link to={"/forgot"}>Forgot Password</Link></label>
                            <input type="password" id="password" ref={passwordRef} placeholder='password' pattern="^(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$" required />
                        </div>
                        <button disabled={loading || currentUser} onClick={handleLogin}>Login</button>
                    </form>
                    {/* <div className="alternate">
                        <h2>Or Login With:</h2>
                        <button>Google</button>
                        <button>Microsoft</button>
                        <button>Apple</button>
                        <button>Github</button>
                    </div> */}
                </div>
                <Background />
            </section>
        </>
    )
}

export default Login;