// Test Default
import logo from './logo.svg';
import './style/DefaultApp.css';

// CSS
import './style/variables.css';
import './style/logo.css';

// React
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import { Helmet } from 'react-helmet';

import { default as Header } from './symbols/navbar'
import { default as Footer } from './symbols/footer'

import { useAuth } from "./firebase";

import { default as Login } from './pages/login'
import { default as Signup } from './pages/signup'
import { default as Me } from './pages/me'
import { default as Profile } from './pages/profile'
import { default as UserUpdate } from './pages/userUpdate'
import { default as NewPost } from './pages/newPost'
import { default as Post } from './pages/post'
import { default as Feed } from './pages/feed'
import { default as Dashboard } from './pages/dashboard'

function App() {
  return (<>
    <Router>
      <Header />
      <Routes>
        {/* Home */}
        <Route path='/' element={<DefaultApp />} />
        {/* Accounts */}
        <Route path='login' element={<Login />} />
        <Route path='signup' element={<Signup />} />
        <Route path='forgot' element={<Login />} />
        {/* Signed In */}
        <Route path='me' element={<Me />}>
          <Route path='profile' element={<Profile />} />
          <Route path='account' element={<UserUpdate />} />
        </Route>
        <Route path='dashboard' element={<Dashboard />} />
        {/* post(s) */}
        <Route path='post'>
          <Route index element={<NewPost />} />
          <Route path=':postID' element={<Post />} />
        </Route>
        <Route path='feed' element={<Feed />} />
      </Routes>
      <Footer />
    </Router>
  </>
  );
}

function DefaultApp() {
  const currentUser = useAuth();

  return (

    <>
      {!currentUser && <Helmet>
        <title>Homepage | {appName} | {network}</title>
        <meta name="description" content="Login for an {appName} account. | {appName} is a development platform testing React and Firebase login. | {network}" />
      </Helmet>}
      {currentUser && <Helmet>
        <title>Homepage | {currentUser?.email} | {appName} | {network}</title>
        <meta name="description" content="Login for an {appName} account. | {appName} is a development platform testing React and Firebase login. | {network}" />
      </Helmet>}
      {currentUser?.displayName && <Helmet>
        <title>Homepage | {currentUser?.displayName} | {appName} | {network}</title>
        <meta name="description" content="Login for an {appName} account. | {appName} is a development platform testing React and Firebase login. | {network}" />
      </Helmet>}
      <div className="App">
        <div className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <div>Currently logged in as: {currentUser?.email} </div>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </div>
      </div>
    </>
  )
}

export default App;

export const appName = "ACRON"
export const network = "xcwalker.dev"