function Footer() {
    return (
        <div className="App">
        <div className="App-header">
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            React Test
          </a>
        </div>
      </div>
      )
}

export default Footer;