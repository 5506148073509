
import { logout, useAuth } from "../firebase";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import '../style/navbar.css';
import '../style/menu.css';

function NavBar() {
    const navScroll = () => {
        document.addEventListener("scroll", e => {
            if (document.body.getBoundingClientRect().top < 0) {
                document.body.classList.add("scrolled")
            } else if (document.body.getBoundingClientRect().top >= 0) {
                document.body.classList.remove("scrolled")
            }
        })
    }

    // showNav
    const showNav = (vis) => {
        var body = document.body;
        var nav = document.querySelector("nav");

        console.log("showNav Click")

        if (nav != null) {
            if (nav.classList.contains("visible")) {
                nav.classList.remove("visible")
                body.classList.remove("noscroll")
            } else if (!nav.classList.contains("visible")) {
                nav.classList.add("visible")
                body.classList.add("noscroll")
            }

            if (vis === false) {
                nav.classList.remove("visible")
                body.classList.remove("noscroll")
            }
        } else {
            console.error("Nav == Null")
        }
    }

    // showNav
    const showMenu = (vis) => {
        var menu = document.querySelector("section.menu");

        console.log("showMenu Click")

        if (menu != null) {
            if (menu.classList.contains("visible")) {
                menu.classList.remove("visible")
            } else if (!menu.classList.contains("visible")) {
                menu.classList.add("visible")
            }

            if (vis === false) {
                menu.classList.remove("visible")
            }
        } else {
            console.error("Menu == Null")
        }
    }

    const currentUser = useAuth();
    const [photoURL, setPhotoURL] = useState("https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png");

    useEffect(() => {
        if (currentUser?.photoURL) {
            setPhotoURL(currentUser.photoURL);
        }
    }, [currentUser])

    async function handleLogout() {
        try {
            await logout();
        } catch {
            alert("Error!");
        }
    }

    return (
        <>
            <header className='scrolled' onLoad={navScroll()}>
                <div className="container">
                    <div className="logo">
                        <span>Acron</span>
                    </div>
                    <div className="nav">
                        <nav>
                            <Link to="/" onClick={() => { showNav(false) }}>Home</Link>
                            {!currentUser && <Link to="login" onClick={() => { showNav(false); showMenu(false); }} >Login</Link>}
                            {!currentUser && <Link to="signup" onClick={() => { showNav(false); showMenu(false); }}>Signup</Link>}

                            {currentUser && <>
                                <Link to="dashboard">Dashboard</Link>
                            </>}
                        </nav>
                        {currentUser && <>
                            <button className="type-2" onClick={() => { showMenu() }} onBlur={() => { showMenu(false) }}>
                                <img src={photoURL} alt="Avatar" className="avatar" />
                            </button>
                        </>}
                        <button href="" className="navMenu" id='nav-btn' aria-label='NavMenu' onClick={() => { showNav() }}>
                            <svg version="1.1" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                <path d="m-3-3h24v24h-24z" fill="none"></path>
                                <path d="m1 15h16c0.55 0 1-0.45 1-1s-0.45-1-1-1h-16c-0.55 0-1 0.45-1 1s0.45 1 1 1zm0-5h16c0.55 0 1-0.45 1-1s-0.45-1-1-1h-16c-0.55 0-1 0.45-1 1s0.45 1 1 1zm-1-6c0 0.55 0.45 1 1 1h16c0.55 0 1-0.45 1-1s-0.45-1-1-1h-16c-0.55 0-1 0.45-1 1z">
                                </path>
                            </svg>
                        </button>
                    </div>
                </div>
            </header>
            {currentUser &&
                <section className="menu">
                    <div className="container">
                        <div className="part">
                            <Link to="me" onClick={() => { showMenu(false) }}>Me</Link>
                            <Link to="me/profile" onClick={() => { showMenu(false) }}>Profile</Link>
                            <Link to="me/account" onClick={() => { showMenu(false) }}>Account</Link>
                        </div>
                        <div className="seperator" />
                        <div className="part">
                            <button className="logout" onClick={() => { showMenu(false); handleLogout(); }}>Logout</button>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default NavBar;