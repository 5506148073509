// imports
import { useState } from "react";
import ReactMarkdown from 'react-markdown'
import { Link, useParams } from "react-router-dom"
import { getImageInfo, getPost, getUserInfo } from "../firebase";

import "../style/post.css"
import { Helmet } from "react-helmet";
import { appName, network } from '../App'

export default function Posts() {
    const params = useParams();
    const [doc, setDoc] = useState();
    const [user, setUser] = useState();
    const [photos, setPhotos] = useState([]);

    // if it works, don't mess with it
    // seriously... don't
    getPost(params.postID).then(res => {
        // console.log(res)
        setDoc(res)
        getUserInfo(res.user).then(resp => {
            setUser(resp)
        })
        for (let i = 0; i < res.images.length; i++) {
            getImageInfo(res.images[i]).then(resp => {
                var arr = photos;
                // console.log(resp, arr)
                if (arr.some(e => e.url === resp.url)) return
                arr.push(resp)
                setPhotos(arr);
                console.log(arr)
            })
        }
    })

    return <>
        <Helmet>
            <title>Post | {appName} | {network}</title>
            <meta name="description" content="{doc?.title} by {doc?.displayname} | {appName} is a development platform testing React and Firebase login. | {network}" />
        </Helmet>
        {doc?.title && <Helmet>
            <title>{doc.title} | Post | {appName} | {network}</title>
            <meta name="description" content="{doc?.title} by {doc?.displayname} | {appName} is a development platform testing React and Firebase login. | {network}" />
        </Helmet>}
        <section className="post">
            <div className="container">
                <div className="post-item">
                    <div className="header">
                        <Link className="avatar" to={"/user/" + doc?.user}>
                            <img src={user?.photoURL} alt="" />
                        </Link>
                        <div className="info">
                            <Link className="displayName" to={"/user/" + doc?.user}>
                                {user?.displayname}
                            </Link>

                            <span>{doc?.title}</span>
                        </div>
                    </div>
                    <div className="content">
                        <ReactMarkdown>{doc?.body}</ReactMarkdown>
                        {photos.length > 0 && <div className="images">
                            {photos.map((post) => {
                                console.log(post.type)
                                if (post.type === "photo") {
                                    return <div className="image" key={post}>
                                        <img src={post.url} alt="" />
                                    </div>
                                } else if (post.type === "video") {
                                    return <div className="image" key={post}>
                                        <video src={post.url} alt="" loop={true} controls={true} autoPlay={true} muted={true}></video>
                                    </div>
                                } else {
                                    return <div></div>
                                }
                            })}
                        </div>}
                    </div>
                    <div className="controls">
                        <div className="section">
                            <button className="like">LIKE</button>
                            <span>XX</span>
                        </div>
                        <div className="section">
                            <button className="share">SHARE</button>
                            <span>XX</span>
                        </div>
                    </div>
                </div>
                <div className="replies"></div>
            </div>
        </section>
    </>
}

