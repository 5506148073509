import '../style/background.css'
import { appName, network } from '../App'

function Background() {
    return <div id="defaultBackground">
        <div className="container">
            <p style={{ '--index': 0 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 1 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 2 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 3 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 4 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 5 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 6 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 7 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 8 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 9 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 10 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 11 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 12 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 13 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 14 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 15 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 16 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 17 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 18 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 19 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 20 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 21 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 22 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
            <p style={{ '--index': 23 }} >{appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network} | {appName} | {network}</p>
        </div>
    </div >
}

export default Background;