import { useRef, useEffect, useState } from "react";
import { useAuth, uploadProfilePicture, updateDisplayName, updateFirstName, updateLastName, uploadProfileBackgroundPicture } from "../firebase";
import { Link } from "react-router-dom";

import '../style/modal.css'

export default function Profile() {
    const currentUser = useAuth();
    const [photo, setPhoto] = useState(null);
    const [backgroundPhoto, setBackgroundPhoto] = useState(null);
    const [nickInput, setNickInput] = useState(null);
    const [firstNInput, setFirstNInput] = useState(null);
    const [lastNInput, setLastNInput] = useState(null);
    const [loading, setLoading] = useState(false);
    const [photoURL, setPhotoURL] = useState("https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png");
    const displayNameRef = useRef();
    const firstNameRef = useRef();
    const lastNameRef = useRef();

    function handleFileChange(e) {
        if (e.target.files[0]) {
            setPhoto(e.target.files[0])
        }
    }

    function handleFileClick() {
        uploadProfilePicture(photo, currentUser, setLoading);
    }

    function handleBackgroundFileChange(e) {
        if (e.target.files[0]) {
            setBackgroundPhoto(e.target.files[0])
        }
    }

    function handleBackgroundFileClick() {
        uploadProfileBackgroundPicture(backgroundPhoto, currentUser, setLoading);
    }

    function handleNickChange() {
        setNickInput(displayNameRef.current.value);
    }

    function handleNickClick() {
        updateDisplayName(displayNameRef.current.value, currentUser, setLoading)
    }

    function handleFirstNChange() {
        setFirstNInput(firstNameRef.current.value);
    }

    function handleFirstNClick() {
        updateFirstName(firstNameRef.current.value, currentUser, setLoading)
    }

    function handleLastNChange() {
        setLastNInput(lastNameRef.current.value);
    }

    function handleLastNClick() {
        updateLastName(lastNameRef.current.value, currentUser, setLoading)
    }

    useEffect(() => {
        if (currentUser?.photoURL) {
            setPhotoURL(currentUser.photoURL);
        }
    }, [currentUser])

    return (
        <>
            <section className="modal">
                <div className="container">
                    <div className="header">
                        <h2>Modify Profile</h2>
                        <Link to='../' className="close">
                            <svg version="1.1" viewBox="0 0 13.185 13.185" xmlns="http://www.w3.org/2000/svg">
                                <path d="m-5.4075-5.4075h24v24h-24z" fill="none" />
                                <path d="m12.892 0.3025c-0.39-0.39-1.02-0.39-1.41 0l-4.89 4.88-4.89-4.89c-0.39-0.39-1.02-0.39-1.41 0s-0.39 1.02 0 1.41l4.89 4.89-4.89 4.89c-0.39 0.39-0.39 1.02 0 1.41s1.02 0.39 1.41 0l4.89-4.89 4.89 4.89c0.39 0.39 1.02 0.39 1.41 0s0.39-1.02 0-1.41l-4.89-4.89 4.89-4.89c0.38-0.38 0.38-1.02 0-1.4z" />
                            </svg>
                        </Link>
                    </div>
                    <div className="content">
                        <div className="side">
                            <div className="item type-2">
                                <div className="part fit">
                                    <img src={photoURL} alt="Avatar" className="avatar" />
                                </div>
                                <div className="part">
                                    <label htmlFor="displayName">Display Name</label>
                                    <div className="input">
                                        <input type="text" id="displayName" ref={displayNameRef} onChange={handleNickChange} placeholder="Anonymous" />
                                        <button disabled={loading || !nickInput} onClick={handleNickClick}>Update</button>
                                    </div>
                                    <label htmlFor="avatar">Avatar</label>
                                    <div className="input">
                                        <label htmlFor="avatar" className="file-input">Upload Avatar
                                            <input type="file" id="avatar" onChange={handleFileChange} accept=".jpg, .jpeg, .png, .apng, .webp, .webm, .gif" />
                                        </label>
                                        <button disabled={loading || !photo} onClick={handleFileClick}>Upload</button>
                                    </div>
                                    <label htmlFor="background">Background</label>
                                    <div className="input">
                                        <label htmlFor="background" className="file-input">Upload Background
                                            <input type="file" id="background" onChange={handleBackgroundFileChange} accept=".jpg, .jpeg, .png, .apng, .webp, .webm, .gif" />
                                        </label>
                                        <button disabled={loading || !backgroundPhoto} onClick={handleBackgroundFileClick}>Upload</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="side">
                            <div className="item type-2">
                                <div className="part">
                                    <label htmlFor="firstName">First Name</label>
                                    <div className="input">
                                        <input type="text" id="firstName" ref={firstNameRef} onChange={handleFirstNChange} placeholder="John" />
                                        <button disabled={loading || !firstNInput} onClick={handleFirstNClick}>Update</button>
                                    </div>
                                    <label htmlFor="lastName">Last Name</label>
                                    <div className="input">
                                        <input type="text" id="lastName" ref={lastNameRef} onChange={handleLastNChange} placeholder="Smith" />
                                        <button disabled={loading || !lastNInput} onClick={handleLastNClick}>Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}