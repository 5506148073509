// embeds
import { useRef, useState } from "react";
import { signup, useAuth } from "../firebase";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { default as Background } from '../symbols/background'
import { appName, network } from '../App'

// css
import '../style/login.css'

function Login() {
    const [loading, setLoading] = useState(false);
    const currentUser = useAuth();
    var error = null;

    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordRepeatRef = useRef();

    async function handleSignup(e) {
        e.preventDefault();
        if (passwordRef.current.value === passwordRepeatRef.current.value) {
            setLoading(true);
            try {
                await signup(emailRef.current.value, passwordRef.current.value);
            } catch (errorReturn) {
                error = errorReturn;
            }
            setLoading(false);
        }
    }

    return (
        <>
            <Helmet>
                <title>Signup | {appName} | {network}</title>
                <meta name="description" content="Signup for an {appName} account. | {appName} is a development platform testing React and Firebase login. | {network}" />
            </Helmet>
            <section className="login">
                <div className="container">
                    {currentUser && <Navigate to='/me' />}
                    <form action="">
                        <h2>Signup</h2>
                        {error && <div>Error: {error?.code} </div>}
                        {error && <div>Error: {error?.message} </div>}
                        <div className="group">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" ref={emailRef} placeholder='example@acron.xcwalker.dev' pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?" required />
                        </div>
                        <div className="group">
                            <label htmlFor="password">Password</label>
                            <input type="password" id="password" ref={passwordRef} placeholder='password' pattern="^(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$" required />
                            <span>Password Must Be At Least 8 Characters Long</span>
                        </div>
                        <div className="group">
                            <label htmlFor="passwordRepeat">Repeat Password</label>
                            <input type="password" id="passwordRepeat" ref={passwordRepeatRef} placeholder='password' pattern="^(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$" required />
                        </div>
                        <button disabled={loading || currentUser} onClick={handleSignup}>Signup</button>
                    </form>
                    {/* <div className="alternate">
                        <h2>Or Signup With:</h2>
                        <button>Google</button>
                        <button>Microsoft</button>
                        <button>Apple</button>
                        <button>Github</button>
                    </div> */}
                </div>
                <Background />
            </section>
        </>
    )
}

export default Login;